import {
    copmleteResetPassword,
    requestResetPasswordEp,
    loginWithBikekey,
    signUpWithBikekey,
    completeVerifyEmail,
    getUserToken,
    signupWithGoogle,
    loginWithGoogle,
} from '../apis/identities'
import { CreateIdentity, LoginParams } from '../types/identity'
import { storeAuthToken } from '../utils/auth-token'
import { navigate } from 'gatsby'
import { getItem, removeItem, tokenPaths } from '../utils/localStorage'
import { redirectIfEligible } from '../utils/redirect'
import { ActionAreas, initPixel, logAction } from '../apis/logger'
import * as uuid from 'uuid'
import { webshopApi } from '../redux/services/webshop'
import { chatsApi } from '../redux/services/chats'
import bikekeyApi from '../redux/services/bikekeyApi'
import { navigateToIndex, navigateToPage } from '../components/blocks/pageWrappers/pageMap'
import { Session, loadSession } from '../apis/hooks/useAuth'
import { GoogleAuthUserInfo } from '../types/google_auth'

export async function createUser(
    values: CreateIdentity,
    disableRedirect = false
): Promise<{ hasError: boolean; errorMessage: string | null; session: Session | null }> {
    try {
        const data: CreateIdentity = {
            email: values.email,
            phone: values.phone,
            password: values.password,
            zip_code: values.zip_code,
            skip_email: values.skip_email,
        }
        const friendRef = getItem(tokenPaths.friendRef)
        if (values.friend_ref) {
            data.friend_ref = values.friend_ref
        } else if (friendRef) {
            data.friend_ref = friendRef
        }
        if (values.user_ref) {
            data.user_ref = values.user_ref
        } else {
            const userRef = getItem(tokenPaths.userRef)
            if (userRef) {
                data.user_ref = userRef
            }
        }
        const authToken = await signUpWithBikekey(data)
        removeItem(tokenPaths.friendRef)
        removeItem(tokenPaths.userRef)
        storeAuthToken(authToken)
        initPixel(loadSession(authToken))
        const fbEventId = `signup-${uuid.v4()}`
        const fbEventName = 'CompleteRegistration'
        await logAction(ActionAreas.UserEvent, 'signup', {
            event_name: fbEventName,
            fb_event_id: fbEventId,
            event_source_url: window.location.href,
        })
        if (!disableRedirect) {
            navigateToIndex(19)
        }
        return { hasError: false, errorMessage: null, session: loadSession(authToken) }
    } catch (error) {
        const result = { hasError: true, errorMessage: '', session: null }
        if (error.status === 422) {
            result.errorMessage = error.data.detail[0].message
            return result
        } else if (error.status === 400) {
            result.errorMessage = error.data.detail
            try {
                const errorData = JSON.parse(result.errorMessage)
                if (errorData?.pre_registered_policy && errorData?.insurer_party_id) {
                    navigateToPage(
                        `/insurance/${errorData.insurer_party_id}/policy/contents-signup/`
                    )
                    return { hasError: true, errorMessage: '', session: null }
                }
            } catch {}
            return result
        }
        result.errorMessage = error.toString()
        return result
    }
}

export function goToApp(path = '/app', newWindow = false, session: Session | null = null) {
    const didRedirect = redirectIfEligible(newWindow)
    if (didRedirect) {
        return
    } else {
        if (path === '/app' && !newWindow && session?.organisation_type === 'insurance_company') {
            navigateToPage(`/insurance/${session?.party_id}/admin`)
            return
        } else if (newWindow) {
            window.open(path, '_blank')
        } else {
            if (path === window.location.pathname || path + '/' === window.location.pathname) {
                window.location.reload()
            } else {
                navigate(path)
            }
        }
    }
}

export async function login(dispatch: any, values: LoginParams, disableNavigation = false) {
    try {
        const authToken = await loginWithBikekey({
            email: values.email,
            password: values.password,
        })
        if (!authToken) return
        storeAuthToken(authToken)
        dispatch(webshopApi.util.resetApiState())
        dispatch(chatsApi.util.resetApiState())
        dispatch(bikekeyApi.util.resetApiState())
        if (!disableNavigation) {
            const session = loadSession(authToken)
            goToApp('/app', false, session)
        }
    } catch (error) {
        if (error.status === 422) {
            return error.data.detail[0].message
        } else if (error.status === 400) {
            return error.data.detail
        }
        return error.toString()
    }
}

export async function switchUser(dispatch: any, newUserPartyId: string) {
    try {
        const authToken = await getUserToken(newUserPartyId)
        if (!authToken) return
        signInWithToken(dispatch, authToken)
    } catch (error) {
        if (error.status === 422) {
            return error.data.detail[0].message
        } else if (error.status === 400) {
            return error.data.detail
        }
        return error.toString()
    }
}

export async function signInWithToken(dispatch: any, authToken: string, newWindow = false) {
    if (!authToken) return
    storeAuthToken(authToken)
    dispatch(webshopApi.util.resetApiState())
    dispatch(chatsApi.util.resetApiState())
    dispatch(bikekeyApi.util.resetApiState())
    const session = loadSession(authToken)
    goToApp('/app', newWindow, session)
}

export async function resetPassword(newPassword: string, token: string) {
    try {
        const authToken = await copmleteResetPassword(
            {
                new_password: newPassword,
            },
            token
        )
        if (!authToken) return
        storeAuthToken(authToken)
        const session = loadSession(authToken)
        goToApp('/app', false, session)
    } catch (error) {
        if (error.status === 422) {
            return error.data.detail[0].message
        }
        return 'Kunne ikke nulstille password'
    }
}

export async function requestResetPassword(email: string) {
    try {
        const authToken = await requestResetPasswordEp({
            email,
        })
    } catch (error) {
        if (error.status === 422) {
            return error.data.detail[0].message
        }
        return 'Kunne ikke nulstille password'
    }
}

export async function validateEmail(token: string) {
    const authToken = await completeVerifyEmail(token)
    if (!authToken) return
    storeAuthToken(authToken)
}

export async function createGoogleUser(values: GoogleAuthUserInfo, disableRedirect = false) {
    try {
        const data: GoogleAuthUserInfo = {
            google_id: values.google_id,
            google_email: values.google_email,
            google_name: values.google_name,
        }
        const authToken = await signupWithGoogle(data)
        storeAuthToken(authToken)
        initPixel(loadSession(authToken))
        const fbEventId = `signup-${uuid.v4()}`
        const fbEventName = 'CompleteRegistration'
        logAction(ActionAreas.UserEvent, 'signup', {
            event_name: fbEventName,
            fb_event_id: fbEventId,
            event_source_url: window.location.href,
        })
        if (!disableRedirect) {
            navigateToIndex(19)
        }
        return { hasError: false, errorMessage: null, session: loadSession(authToken) }
    } catch (error) {
        const result = { hasError: true, errorMessage: null, session: null }
        if (error.status === 422) {
            result.errorMessage = error.data.detail[0].message
            return result
        } else if (error.status === 400) {
            result.errorMessage = error.data.detail
            return result
        }
        result.errorMessage = error.toString()
        return result
    }
}

export async function loginGoogle(dispatch: any, google_id: string, disableNavigation = false) {
    try {
        const authToken = await loginWithGoogle({
            googleUserId: google_id,
        })
        if (!authToken) return
        storeAuthToken(authToken)
        dispatch(webshopApi.util.resetApiState())
        dispatch(chatsApi.util.resetApiState())
        dispatch(bikekeyApi.util.resetApiState())
        if (!disableNavigation) {
            const session = loadSession(authToken)
            goToApp('/app', false, session)
        }
    } catch (error) {
        if (error.status === 422) {
            return error.data.detail[0].message
        } else if (error.status === 400) {
            return error.data.detail
        }
        return error.toString()
    }
}
